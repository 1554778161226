const html = document.querySelector('html')

////////////////////////////////////////////////////////////////////
////////////// СЛАЙДЕРЫ ////////////////////////////////////////////
////////////////////////////////////////////////////////////////////

import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { init } from 'aos';

//------- КЕЙСЫ -----------------------------------------------//
var slider_cases = document.querySelector("[data-slider-cases]");
if (slider_cases) {
  // console.log(`slider is present`);

  var slider = new Splide('[data-slider-cases]', {
    type: 'loop',
    autoplay: true,
    interval: 2000,
    speed: 1000,
    perPage: 3,
    focus: 0,
    pagination: false,
    arrows: true,
    gap: 20,
    breakpoints: {
      1024: {
        perPage: 2,
      },
      768: {
        perPage: 2,
      },
      620: {
        perPage: 1,
      },
    },
  }).mount();

} else {
  // console.log(`slider is NOT present`);
}
//------- ОТЗЫВЫ -----------------------------------------------//
var slider_testim = document.querySelector("[data-slider-testim]");
if (slider_testim) {
  // console.log(`slider is present`);

  var slider = new Splide('[data-slider-testim]', {
    type: 'loop',
    autoplay: true,
    interval: 20000,
    speed: 1000,
    perPage: 2,
    focus: 0,
    pagination: false,
    arrows: true,
    gap: 20,
    breakpoints: {
      767: {
        perPage: 1,
      },
    },
  }).mount();

  let texts = slider_testim.querySelectorAll('[data-slider-testim-desc]')
  texts.forEach(text => {
    text.addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('active')
    })
  })

} else {
  // console.log(`slider is NOT present`);
}

///////////  G S A P  /////////////////////////////////

// import { gsap } from "gsap"
// import { ScrollTrigger } from "gsap/ScrollTrigger"
// gsap.registerPlugin(ScrollTrigger);

///////////  АНИМАЦИЯ ЦИФР СТАТИСТИКИ НА ГЛАВНОЙ  ///////////////
let stats_list = document.querySelector('[data-stats-list]')
if (stats_list) {
  if ("IntersectionObserver" in window) {
    let arr = []
    let stats = Array.from(stats_list.querySelectorAll('[data-stats-num]'))
    stats.forEach(item => {
      arr.push(parseInt(item.textContent))
      item.textContent = 0
    })
    function handleCounter(item, index) {
      let number_init = arr[index]
      let counter = 0

      let intervalId = setInterval(() => {
        counter++
        item.textContent = counter
        if (counter === number_init) {
          clearInterval(intervalId)
        }
      },50)
    }
    const observer = new IntersectionObserver((entries)=>{
      entries.forEach((entry,index) => {
        if (entry.isIntersecting) {
          handleCounter(entry.target, index)
          observer.unobserve(entry.target)
        }
      })
    }, {
      threshold: 1,
      root: document,
      })
      stats.forEach(item =>{
        observer.observe(item)
      })
    }
}

///////////  ПАРАЛЛАКС  /////////////////////////////////////////
let hero = document.querySelector('.hero')
if (hero) {
  initParallax()
  window.addEventListener('resize', () => {
    initParallax()
  })
  function initParallax() {
    if (window.innerWidth > 767) {
      window.addEventListener('scroll', handleParallax)
      handleParallax()
    } else {
      window.removeEventListener('scroll', handleParallax)
      hero.removeAttribute('style')
    }
  }  function handleParallax() {
    let h_init = `${hero.offsetWidth / 1.781}`
    let h_limit = h_init / 2
    let { scrollY } = window
    if (h_limit > scrollY) {
      hero.style.height = `${h_init - scrollY}px`
      hero.style.marginTop = `${scrollY/2}px`
    }
  }
}

///////////////////////////////////////////////////////////////////
//////////// ПОПАПЫ ///////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

// ------- ПОПАП НАПИСАТЬ НАМ ------------------------
let popup_write_us = document.querySelector('[data-popup-write-us]')
if (popup_write_us) {
  let write_us_btns = document.querySelectorAll('[data-write-us-btn]')
  write_us_btns.forEach(btn => {
    btn.addEventListener('click', () => {
      html.classList.add('fixed')
      popup_write_us.showModal()
    })
  })
  let checkbox = popup_write_us.querySelector('.write-us__form-check [type="checkbox"]')
  let label = popup_write_us.querySelector('.write-us__form-check label')
  let btn = popup_write_us.querySelector('.popup__submit')
  checkbox.addEventListener('change', (e) => {
    if (e.currentTarget.checked === true) {
      btn.removeAttribute('disabled')
    } else {
      btn.setAttribute('disabled', true)
    }
  })
  label.addEventListener('click', (e) => {
    if (btn.getAttribute('disabled')) {
      btn.removeAttribute('disabled')
    } else {
      btn.setAttribute('disabled', true)
    }
  })
}
// ------- ПОПАП ОСТАВИТЬ ОТЗЫВ ------------------------
let popup_testim = document.querySelector('[data-popup-testim]')
if (popup_testim) {
  let testim_btn = document.querySelector('[data-popup-testim-btn]')
  testim_btn.addEventListener('click', () => {
    html.classList.add('fixed')
    popup_testim.showModal()
  })
  let checkbox = popup_testim.querySelector('.write-us__form-check [type="checkbox"]')
  let label = popup_testim.querySelector('.write-us__form-check label')
  let btn = popup_testim.querySelector('.popup__submit')
  checkbox.addEventListener('change', (e) => {
    if (e.currentTarget.checked === true) {
      btn.removeAttribute('disabled')
    } else {
      btn.setAttribute('disabled', true)
    }
  })
  label.addEventListener('click', (e) => {
    if (btn.getAttribute('disabled')) {
      btn.removeAttribute('disabled')
    } else {
      btn.setAttribute('disabled', true)
    }
  })
}
// ------- ПОПАП ЗАКАЗ УСЛУГИ ПО ТАРИФУ ------------------------
let popup_zakaz = document.querySelector('[data-popup-zakaz-service]')
if (popup_zakaz) {
  let zakaz_btns = Array.from(document.querySelectorAll('[data-zakaz-btn]'))
  zakaz_btns.forEach(btn => {
    btn.addEventListener('click', (e) => {
      html.classList.add('fixed')
      let target = e.currentTarget
      let parent_card = target.closest('.price-card')
      let service = document.querySelector('h1').textContent
      let tarif = parent_card.querySelector('.price-card__top-title').textContent
      // popup_zakaz.querySelector('[data-zakaz-service]').textContent = service
      // popup_zakaz.querySelector('[data-zakaz-tarif]').textContent = tarif
      // popup_zakaz.querySelector('[data-hidden-service]').value = service
      // popup_zakaz.querySelector('[data-hidden-tarif]').value = tarif
      popup_zakaz.showModal()
    })
  })
  // let checkbox = popup_zakaz.querySelector('.write-us__form-check [type="checkbox"]')
  // let label = popup_zakaz.querySelector('.write-us__form-check label')
  // let btn = popup_zakaz.querySelector('.popup__submit')
  // checkbox.addEventListener('change', (e) => {
  //   if (e.currentTarget.checked === true) {
  //     btn.removeAttribute('disabled')
  //   } else {
  //     btn.setAttribute('disabled', true)
  //   }
  // })
  // label.addEventListener('click', (e) => {
  //   if (btn.getAttribute('disabled')) {
  //     btn.removeAttribute('disabled')
  //   } else {
  //     btn.setAttribute('disabled', true)
  //   }
  // })
}
// ------- ПОПАП ЗАКАЗАТЬ ЗВОНОК ------------------------
let popup_order_call = document.querySelector('[data-popup-order-call]')
if (popup_order_call) {
  let order_call_btn = document.querySelector('[data-order-call-btn]')
  order_call_btn.addEventListener('click', () => {
    html.classList.add('fixed')
    popup_order_call.showModal()
  })
  const copy_tel_btn = popup_order_call.querySelector('[data-copy-tel-btn]')
  let tel = document.querySelector('[data-tel]')
  let tel_num = ''
  copy_tel_btn.addEventListener('click', () => {
    if (tel_num === '') {
      tel_num = tel.textContent
      tel.textContent = 'Номер скопирован'
      tel.classList.add('copied')
      navigator.clipboard.writeText(tel_num)
    }
  })
}
// ------- ПОПАП ЗАПОЛНИТЬ БРИФ ------------------------
let popup_brif = document.querySelector('[data-popup-brif]')
if (popup_brif) {
  let brif_btn = document.querySelector('[data-brif-btn]')
  brif_btn.addEventListener('click', () => {
    html.classList.add('fixed')
    popup_brif.showModal()
  })
}
// ------- ПОПАП ВИДЕО ЭКСПЛЕЙНЕР ------------------------
let popup_videoexp = document.querySelector('[data-popup-videoexp]')
if (popup_videoexp) {
  let videoexp_btn = document.querySelector('[data-videoexp-btn]')
  videoexp_btn.addEventListener('click', () => {
    html.classList.add('fixed')
    popup_videoexp.showModal()
  })
}
//------------ КНОПКИ ЗАКРЫТИЯ ПОПАПОВ -------------------
const popup_close_btns = document.querySelectorAll('.popup-close-btn')
if (popup_close_btns) {
  popup_close_btns.forEach(btn => {
    btn.addEventListener('click', () => {
      document.querySelector('dialog[open]').close()
    })
  })
}
//------------ КЛИКИ ВНЕ ПОПАПОВ ДЛЯ ИХ ЗАКРЫТИЯ -------------------
const popups = document.querySelectorAll('dialog')
if (popups) {
  popups.forEach(item => {
    item.addEventListener("click", e => {
      const dialogDimensions = item.getBoundingClientRect()
      if (
        e.clientX < dialogDimensions.left ||
        e.clientX > dialogDimensions.right ||
        e.clientY < dialogDimensions.top ||
        e.clientY > dialogDimensions.bottom
      ) {
        html.classList.remove('fixed')
        item.close()
      }
    })
  })
}

///////////////////////////////////////////////////////////////////
//////////// РАЗНОЕ ///////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

//------------ АККОРДЕОН -------------------------------------------
let ac_container = document.querySelector('.ac-container')
if (ac_container) {
  let btns = ac_container.querySelectorAll(".ac-trigger")
  let panels = ac_container.querySelectorAll(".ac-panel")

  btns.forEach((btn) => {
    btn.addEventListener("click", () => {
      if (btn.classList.contains("active")) {
        reset()
        return
      }
      reset()
      btn.classList.add("active")
      btn.nextElementSibling.classList.add("expanded")
    })
  })
  function reset() {
    panels.forEach((panel) => {
      panel.classList.remove("expanded")
    })
    btns.forEach((btn) => {
      btn.classList.remove("active")
    })
  }
}

//------------ ТАБЫ ДЛЯ БЛОКА ИСПОЛЬЗУЕМЫЕ ТЕХНОЛОГИИ --------------
let tabs_container = document.querySelector('.tabs-container')
if (tabs_container) {
  let tabs = tabs_container.querySelectorAll('.tabs__item')
  let panels = tabs_container.querySelectorAll('.panels__item')
  let i = 0
  tabs.forEach((tab, index) => {
    tab.addEventListener('click', tabClicked)
    tab.setAttribute('data-id', `${index}`)
    panels[index].setAttribute('data-id', `${index}`)
    if (index === 0) {
      tab.classList.add('active')
      panels[0].classList.add('active')
    }
  })
  function tabClicked(e) {
    tabs.forEach((tab, index) => {
      tab.classList.remove('active')
      panels[index].classList.remove('active')
    })
    let target = e.currentTarget
    let index = parseInt(target.getAttribute('data-id'))
    target.classList.add('active')
    panels[index].classList.add('active')
  }



}

// ------- МЕДВЕДЬ ДУМАЕТ ----------------------------
const bear_talk = document.querySelector(".bear__talk")
if (bear_talk) {
  const bear_thoughts = [
    "Ты что дура? Вылазь скорее!",
    "Мммм сейчас бы рыбки...",
    "Придумываю секретный промокод",
    "Промокод: RAWR на 5% скидки",
    "Я тучка тучка тучка и вовсе не медведь"
  ]
  const index = Math.round(Math.random() * (bear_thoughts.length - 1))
  bear_talk.textContent = bear_thoughts[index]
}
// ------- БУРГЕР ----------------------------
const burger = document.querySelector(".header__burger")
if (burger) {
  const mob_menu = document.querySelector('[data-popup-mobile-menu]')
  const mob_menu_close_btn = mob_menu.querySelector('.mobile__close')
  burger.addEventListener('click', () => {
    html.classList.add('fixed')
    mob_menu.classList.add('active')
    mob_menu.showModal()
  })
  mob_menu_close_btn.addEventListener('click', () => {
    html.classList.remove('fixed')
    mob_menu.classList.remove('active')
    mob_menu.close()
  })
  mob_menu.addEventListener("click", e => {
    const dialogDimensions = mob_menu.querySelector('.mobile__content').getBoundingClientRect()
    if (
      e.clientX < dialogDimensions.left ||
      e.clientX > dialogDimensions.right ||
      e.clientY < dialogDimensions.top ||
      e.clientY > dialogDimensions.bottom
    ) {
      html.classList.remove('fixed')
      mob_menu.classList.remove('active')
      mob_menu.close()
    }
  })
}
// ------- ПАСХАЛКИ --------------------------
const pashalka_bear = document.querySelector('[data-pashalka-bear]')
if (pashalka_bear) {
  const bear_wrap = document.querySelector('.rear-trees2-wrap')
  const bear_gif = document.querySelector('.bear-gif')
  pashalka_bear.addEventListener('click', (e) => {
    e.currentTarget.style.pointerEvents = 'none'
    bear_gif.classList.add('go')
  })
  bear_gif.addEventListener('click', bearClicked)
  function bearClicked(){
    let div = document.createElement('div')
    div.classList.add('bear-talk')
    let text = document.createTextNode('Промокод: RAWR на 5% скидки')
    div.appendChild(text)
    bear_wrap.appendChild(div)
    setTimeout(() => {
      div.classList.add('show')
    }, 0)
    bear_gif.removeEventListener('click', bearClicked)
  }
}

// ------- ФИЛЬТРАЦИЯ КЕЙСОВ --------------------------
let filters = document.querySelector('[data-filters]')
if (filters) {
  let input = filters.querySelector('[data-name]')
  let select = filters.querySelector('[data-select]')
  let cards = document.querySelectorAll('.case-card')
  let panels = filters.querySelectorAll('[data-panel]')
  let items = filters.querySelectorAll('[data-item]')
  // оптимизируем запись атрибута data-for
  cards.forEach(card => {
    let data = card.getAttribute('data-for').split(',')
    let new_data = []
    data.forEach(elem => {
      elem = elem.trim()
      new_data.push(elem)
    })
    new_data = new_data.join()
    card.setAttribute('data-for', new_data)
  })
  // активизируем первый фильтр
  items[0].classList.add('active')
  // кнопка скрытия-показа фильтров
  let open = document.querySelector('[data-filters-open]')
  open.addEventListener('click', (e) => {
    filters.classList.toggle('active')
    setTimeout(() => {
      panels.forEach(panel => panel.classList.remove('hide'))
    },300)
  })
  // кнопка скрытия фильтров
  let close = filters.querySelector('[data-close]')
  close.addEventListener('click', (e) => {
    filters.classList.remove('active')
    setTimeout(() => {
      panels.forEach(panel => panel.classList.remove('hide'))
    },300)
  })
  // аккордеон для блоков
  let titles = filters.querySelectorAll('[data-title]')
  titles.forEach(title => {
    title.addEventListener('click', () => {
      title.nextElementSibling.classList.toggle('hide')
    })
  })
  // фильтрация по разделам (дизайн, разработка и т.д.)
  items.forEach((item, index, array) => {
    item.addEventListener('click', () => {
      emptyInput()
      resetSelect()
      array.forEach(elem => elem.classList.remove('active'))
      if (!item.classList.contains('active')) {
        let topic = item.getAttribute('data-for')
        if (topic === "all") {
          showCards()
          item.classList.add('active')
          return
        }
        hideCards()
        cards.forEach(card => {
          let data = card.getAttribute('data-for').split(',')
          if (data.includes(topic)) {
            card.classList.remove('hide')
          }
        })
      }
      item.classList.toggle('active')
    })
  })
  // input
  input.addEventListener('input', (e) => {
    disactivateItems()
    resetSelect()
    hideCards()
    cards.forEach(card => {
      let text = card.querySelector('[data-card-title]').textContent.toLowerCase()
      let target = e.currentTarget.value.toLowerCase()
      if (text.indexOf(target) !== -1) {
        card.classList.remove('hide')
      }
    })
  })
  // select
  select.addEventListener('change', (e) => {
    let target = e.currentTarget.value
    if (target === 'all') {
      showCards()
      emptyInput()
      items[0].classList.add('active')
      return
    }
    hideCards()
    disactivateItems()
    emptyInput()
    cards.forEach(card => {
      let data = card.getAttribute('data-for').split(',')
      if (data.includes(target)) {
        card.classList.remove('hide')
      }
    })
  })
  // функции
  function hideCards() {
    cards.forEach(card => {
      card.classList.add('hide')
    })
  }
  function showCards() {
    cards.forEach(card => {
      card.classList.remove('hide')
    })
  }
  function disactivateItems() {
    items.forEach(item => item.classList.remove('active'))
  }
  function emptyInput() {
    input.value = ''
  }
  function resetSelect() {
    select.selectedIndex = 0
  }
}

// -------- ФИЛЬТРАЦИЯ НОВОСТЕЙ -----------------------
let news_filter = document.querySelector('[data-news-filter]')
if (news_filter) {
  let cards = document.querySelectorAll('.news-card')
  let items = news_filter.querySelectorAll('[data-item]')
  items[0].classList.add('active')
  items.forEach(item => {
    item.addEventListener('click', (e) => {
      let target = e.currentTarget
      if (target.classList.contains('active')) return
      disactivateItems()
      hideCards()
      target.classList.add('active')
      let topic = target.getAttribute('data-for')
      if (topic === 'все') {
        showCards()
        return
      }
      cards.forEach(card => {
        if (card.getAttribute('data-for') === topic) {
          card.classList.remove('hide')
        }
      })
    })
  })
  function hideCards() {
    cards.forEach(card => card.classList.add('hide'))
  }
  function showCards() {
    cards.forEach(card => card.classList.remove('hide'))
  }
  function disactivateItems() {
    items.forEach(item => item.classList.remove('active'))
  }
}

// --------- НОВОСТНОЕ МЕНЮ ---------------------------
let news_menu = document.querySelector('[data-news-menu]')
if (news_menu) {
  let links = news_menu.querySelectorAll('.has-sub > a')
  links.forEach(link => {
    link.addEventListener('click', (e) => {
      let target = e.currentTarget
      let icon = target.querySelector('i')
      if (target.classList.contains('active')) {
        link.classList.remove('active')
        icon.classList.remove('rotate')
        hideSubMenus()
        return
      }
      link.classList.add('active')
      icon.classList.add('rotate')
      link.nextElementSibling.classList.add('active')
    })
  })
  function hideSubMenus() {
    links.forEach(link => {
      link.nextElementSibling.classList.remove('active')
    })
  }
}

// ---------- LIGHTBOX --------------------------------
let galleries = document.querySelectorAll('.gallery')
if (galleries) {
  galleries.forEach((gallery, index) => {
    gallery.setAttribute('data-index',index)
    new SimpleLightbox(`[data-index="${index}"] .btn-lightbox`), {
      overlayOpacity: .7,
      spinner: false,
      close: false,
      showCounter: false,
      heightRatio: 1,
      widthRatio: 1
    }
  })
}

// ---------- ЛИСТАЛКА ДЛЯ СТРАНИЦЫ БРИФ  -------------
let brif = document.querySelector('[data-brif]')
if (brif) {
  let items = brif.querySelectorAll('[data-item]')
  let desc_items = brif.querySelectorAll('[data-desc-item]')
  let btn_prev = brif.querySelector('[data-btn="prev"]')
  let btn_next = brif.querySelector('[data-btn="next"]')
  let btn_submit = brif.querySelector('[data-btn="submit"]')
  let num = brif.querySelector('[data-num]')
  let bar = brif.querySelector('[data-bar]')
  let agreement = brif.querySelector('[data-agreement]')
  let counter = {
    count: 1,
    min: 1,
    max: items.length
  }
  hideItems()
  items[0].classList.remove('hide')
  desc_items[0].classList.add('active')
  agreement.classList.add('hide')
  num.textContent = counter.min
  btn_prev.classList.add('hide')
  btn_submit.classList.add('hide')
  btn_prev.addEventListener('click', onPrev)
  btn_next.addEventListener('click', onNext)
  function onPrev(e) {
    if (counter.count > counter.min) {
      counter.count--
    } else return
    btn_submit.classList.add('hide')
    btn_next.classList.remove('hide')
    agreement.classList.add('hide')
    let target = e.currentTarget
    if (counter.count === counter.min) {
      target.classList.add('hide')
      num.textContent = counter.count
      reset()
      return
    }
    num.textContent = counter.count
    reset()
  }
  function onNext(e) {
    if (counter.count < counter.max) {
      counter.count++
    } else return
    btn_prev.classList.remove('hide')
    let target = e.currentTarget
    if (counter.count === counter.max) {
      target.classList.add('hide')
      btn_submit.classList.remove('hide')
      agreement.classList.remove('hide')
      num.textContent = counter.count
      reset()
      return
    }
    num.textContent = counter.count
    reset()
  }
  function hideItems() {
    items.forEach(item => item.classList.add('hide'))
  }
  function disactivateDescItems() {
    desc_items.forEach(item => item.classList.remove('active'))
  }
  function reset() {
    hideItems()
    items[counter.count - 1].classList.remove('hide')
    disactivateDescItems()
    desc_items[counter.count - 1].classList.add('active')
    bar.style.setProperty('--w', `${100 / items.length * counter.count}%`)
  }
}

// ---------- ТУЛТИПЫ ДЛЯ ТАРИФОВ  --------------------
let price_cards = document.querySelector('[data-price-cards]')
if (price_cards) {
  let items = price_cards.querySelectorAll('[data-item-info]')
  items.forEach(item => {
    let text = item.getAttribute('data-item-info')
    let span = document.createElement('span')
    span.textContent = '?'
    span.classList.add('data-info')
    let tooltip = document.createElement('span')
    tooltip.textContent = text
    span.appendChild(tooltip)
    item.appendChild(span)
    span.addEventListener('click', (e) => {
      let target = e.currentTarget
      let span = target.querySelector('span')
      if (span.classList.contains('active')) {
        target.querySelector('span').classList.remove('active')
        return
      }
      hideTooltips()
      target.querySelector('span').classList.add('active')
    })
  })
  function hideTooltips() {
    items.forEach(item => {
      item.querySelector('span span').classList.remove('active')
    })
  }
}

// ---------- КВИЗ В ФОРМЕ НА СТР. КОНТАКТЫ  --------------------
let quiz_label = document.querySelector('.wpcf7-quiz-label')
if (quiz_label) {
  let question = quiz_label.textContent
  let input = quiz_label.nextElementSibling
  input.setAttribute('placeholder', question)
}

